@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
}
.body-jc {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  background-color: #fff;
  min-height: 100vh;
  color: var(--emperor);
}



.prefoot-jc {
  background: #fff url('/footer-background-jc.svg') repeat-x bottom center;
}
.prefoot-shadow-jc {
  background: linear-gradient(180deg, #dfe0df 0%, #ffffff 100%);
}